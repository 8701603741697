@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import "~bootstrap/scss/bootstrap";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "_colors.scss";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import url('https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet');
@import "_krikos360Mixins";
@import "./mediaqs.scss";
@import '~quill/dist/quill.snow.css';
@include tituloFeature;

$font-family: "montserrat-regular", $font-family-base;

body {
  display: grid;
  flex-direction: column;
  background: #f1f1f1;
  padding: 0;
  margin: 0;
  height: 100%;
  min-height: 100%;
  position: relative;
}

.login {
  background-image: linear-gradient(to bottom right, #b340b3, #6749c6ff);
}

.no-login {
  background: #f2f2f2 !important;
}

html {
  height: 100%;
}

//Esto esta en Bootstrap
//TODO: remover la libreria
// Credit: https://github.com/suitcss/base/
button:focus {
  outline: unset !important;
  outline: unset !important;
}

//este es el DIV que se genera dinamicamente por el
//servicio de Toaster.
//Arregla el error que se genera al mostrar un mensaje.
//Espacio entre divs
.overlay-container {
  display: contents;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #999999 !important;
}

.toast-container.inline {
  position: relative;
  width: 100%;
  pointer-events: auto;
  z-index: 1;
}

.toastr-class {
  pointer-events: auto;
  position: relative;
  margin: 0.5rem;
  padding: 1rem 1rem 1rem 3rem;
  width: 100%;
  border-radius: 0.7rem;
  background-repeat: no-repeat;

  .toast-close-button {
    top: auto;
  }
}

.toast-success {
  color: #087735 !important;
  background-image: url("./assets/check.png") !important;
  background-color: #d1f2de !important;
  background-size: 20px !important;
  background-position: 15px 44% !important;

  button {
    color: #087735 !important;
  }

  .toast-message a,
  .toast-message label {
    color: #087735;
  }

  .toast-message a:hover {
    color: #087735;
    text-decoration: none;
  }
}

.toast-error {
  color: #ce0f0f !important;
  background-color: #ffdcdc !important;
  background-image: url("./assets/warning.png") !important;
  background-size: 20px !important;
  background-position: 15px 44% !important;

  button {
    color: #ce0f0f !important;
  }

  .toast-message a,
  .toast-message label {
    color: #ce0f0f !important;
  }

  .toast-message a:hover {
    color: #ce0f0f !important;
    text-decoration: none;
  }
}

.toast-warning {
  color: #e25314 !important;
  background-color: #ffe1d7 !important;
  background-image: url("./assets/info.png") !important;
  background-size: 20px !important;
  background-position: 15px 44% !important;

  button {
    color: #e25314 !important;
  }

  .toast-message a,
  .toast-message label {
    color: #e25314 !important;
  }

  .toast-message a:hover {
    color: #e25314 !important;
    text-decoration: none;
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c44ace;
  border-radius: 5px;
  border: 3px solid white;
}

::-webkit-scrollbar-thumb:hover {
  background: #b042b9;
}

html,
body {
  scrollbar-width: thin;
  scrollbar-color: #c44ace white;
}

// Remueve el color celeste de autocomplete en los input en Chrome
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  font-family: "montserrat-medium" !important;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  white-space: initial !important;
}

@media screen and (max-width: 400px) {

  .mat-toolbar-row,
  .mat-toolbar-single-row {
    white-space: nowrap !important;
  }
}

canvas {
  display: block;
  box-sizing: border-box;
  height: 270px;
  width: 270px;
}

// estilos especificos para el footer una vez logeado
app-main-k360+app-footer .mat-toolbar.mat-footer {
  margin-top: 1rem;
}

//cambios globales a algunos componentes de material
.mat-stroked-button.mat-primary {
  border-color: $violetaKrikos;
}

.mat-dialog-container {
  border-radius: 1.5rem !important;

  min-height: 250px;
}

.mat-horizontal-content-container {
  padding-bottom: 0 !important;
}

#mat-menu-panel-0:not(.cajaMenu) {
  box-shadow: none !important;
  border-radius: 0 !important;

  button.mat-menu-item {
    padding: 0 !important;
    background: white !important;
    font-family: "montserrat-regular" !important;
    font-weight: 400 !important;
    font-size: 0.8rem !important;
    height: 2.4rem !important;
    padding-left: 16px !important;
  }
}

.flickity-page-dots {
  display: none;
}

.flickity-button.flickity-prev-next-button.next,
.flickity-button.flickity-prev-next-button.previous {
  background: none;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 30%;
    height: 60%;
    transform: translate(-50%, -50%);
    color: #a7a19d;
  }
}

.flickity-button.flickity-prev-next-button.previous {
  position: absolute;
  left: -2.5rem;
}

.flickity-button.flickity-prev-next-button.next {
  position: absolute;
  right: -2.5rem;
}

ul li.carousel-slide {
  height: 300px !important;
}

.mat-tooltip {
  font-family: "montserrat-regular" !important;
}

.left-arrow {
  left: -15px !important;
  background: transparent !important;
}

.left-arrow,
.right-arrow {
  right: -15px !important;
  background: transparent !important;

  mat-icon {
    color: #434040;
  }
}

app-tyc-es,
app-pyp-es,
app-notification-panel {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  ul,
  li,
  div,
  strong,
  b {
    font-family: "montserrat-regular" !important;
    font-size: 13px;
  }
}

app-mensaje-critico {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  ul,
  li,
  div,
  strong,
  b {
    font-family: "montserrat-regular" !important;
  }
}

::ng-deep mat-tooltip-component {
  background: transparent !important;
}

.tooltip-inner {
  background-color: #707070 !important;
}

.arrow {
  display: none !important;
}

.chart-wrapper {
  .left-chart {
    max-width: 650px;
    max-height: 550px;

    ngx-charts-advanced-pie-chart svg {
      position: absolute !important;
      top: -5px !important;
      left: 10px !important;
    }
  }

  .advanced-pie-legend .total-value {
    font-size: 16px;
    font-family: "montserrat-semibold";
  }

  .total-label {
    font-size: 18px !important;
    font-family: "montserrat-regular";
    color: gray;
  }
}

.advanced-pie-legend-wrapper {
  height: 300px !important;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value {
  padding: 1rem 0;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-percent {
  margin-top: 0.5rem;
}

.ngx-charts-outer {
  width: 100%;
}

.toast-container {
  margin-right: 0.5%;
  margin-top: 1.5rem;
  transition: 0.3s ease all;
  font-family: "montserrat-regular" !important;
  font-size: 14px !important;
}

::ng-deep .advanced-pie-legend .legend-items-container .legend-items .legend-item {
  display: inherit !important;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item {
  height: 93px !important;
}

.arrows-config .slick-next {
  right: -27px !important;
}

.arrows-config .slick-prev {
  left: -22px !important;
}

.slick-next {
  right: 0 !important;
  z-index: 1000;

  &::before {
    color: #434040 !important;
    opacity: 1 !important;
  }
}

.slick-disabled {
  opacity: 0.5 !important;
}

.slick-prev {
  left: -2px !important;
  z-index: 0;

  &::before {
    color: #434040 !important;
    opacity: 1 !important;
  }
}

.legend-labels {
  justify-content: center !important;
  background: white !important;
  display: flex !important;
}

@media screen and (max-width: 768px) {
  .hide-img-mobile {
    width: 100% !important;
  }
}

.contentRouter {
  align-items: inherit !important;
  height: 100vh !important;
}

.mat-expansion-panel-content a {
  color: #007bff;
}

.dialog-wrapper {
  overflow-y: scroll;
  max-height: 50vh;
}

app-table-doc.removePointerEvents {
  pointer-events: none;
}

.chat-documentos-clientes {

  mat-dialog-container {
    padding: 0;

    app-dialog-chat-documentos {
      max-height: 500px;
    }
  }
}

.mat-typography .mainContent h2.pagesTitles {
  padding: 1rem 24px;
  margin: 0;
  font-size: 20px;
  font-family: "montserrat-semibold";
}

.conexionesTableWrapperPopup {
  max-width: 81vw;
  width: 81vw;
}

.fake-door-dialog-container {
  
  mat-dialog-container {
    padding: 10px !important;
  }
}