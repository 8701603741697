@charset "UTF-8";

@font-face {
    font-family: 'roboto-black';
    src: url("assets/fonts/Roboto-Black.ttf");
}

@font-face {
    font-family: 'roboto-bold';
    src: url("assets/fonts/Roboto-Bold.ttf");
}

@font-face {
    font-family: 'roboto-italic';
    src: url("assets/fonts/Roboto-Italic.ttf");
}

@font-face { 
    font-family: 'roboto-light';
    src: url("assets/fonts/Roboto-Light.ttf");
}

@font-face {
    font-family: 'roboto-medium';
    src: url("assets/fonts/Roboto-Medium.ttf");
}

@font-face {
    font-family: 'roboto-regular';
    src: url("assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: 'roboto-thin';
    src: url("assets/fonts/Roboto-Thin.ttf");
}

@font-face {
    font-family: 'montserrat-semibold';
    src: url("assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
    font-family: 'montserrat-black';
    src: url("assets/fonts/Montserrat-Black.ttf");
}

@font-face {
    font-family: 'montserrat-bold';
    src: url("assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
    font-family: 'montserrat-medium';
    src: url("assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
    font-family: 'montserrat-regular';
    src: url("assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: 'montserrat-thin';
    src: url("assets/fonts/Montserrat-Thin.ttf");
}


