@use "@angular/material" as mat;
@import "@angular/material/theming";
@import "_colors";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $krikos-palette-primary:  mat-palette($krikos-light-primary);
// $krikos-palette-secondary: mat-palette($krikos-light-primary);
$krikos-palette-primary: mat.define-palette(
  $krikos-light-primary,
  400,
  600,
  200
);
$krikos-palette-secondary: mat.define-palette(
  $krikos-light-secondary,
  800,
  900,
  600
);

// The warn palette is optional (defaults to red).
$krikos-palette-warn: mat.define-palette($krikos-light-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$krikos-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $krikos-palette-primary,
      accent: $krikos-palette-secondary,
      warn: $krikos-palette-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($krikos-app-theme);

#mat-badge-content-1,
.mat-badge-warn .mat-badge-content {
  background: #c44ace !important;
}
.mat-menu-item {
  font-family: "montserrat-regular" !important;
  line-height: 38px !important;
  height: 38px !important;
}

@media screen and (max-width: 725px) {
  .cdk-overlay-pane {
    right: 0 !important;
  }
}
