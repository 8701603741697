@import "_colors";
@import "../../../mediaqs.scss";

@mixin tabla {
  //tabla
  .mat-table.krikos {
    width: 100%;
    table-layout: fixed;
    background-color: #f1f1f1;
    font-family: "roboto-light";
    //cabecera
    & .mat-header-cell {
      font-size: 15px;
      color: #999;
      text-align: center;
      margin-right: 2px;
      font-family: "roboto-medium";
    }

    //css pildora
    & .status-pill {
      display: inline-block;
      width: 125px;
      height: 25px;
      line-height: 26px;
      text-align: center;
      font-size: 12px;
      color: #fff;
      border-radius: 5px;
      font-family: "roboto-medium";

      &.active {
        background-color: #03ce55;
      }

      &.inactive {
        background-color: #ff2745;
      }
    }

    & .edit {
      display: none;
    }

    // columnas
    .mat-column-position {
      text-align: center !important;
    }
    .mat-column-name {
      text-align: center !important;
    }
    .mat-column-estructura {
      text-align: center !important;
    }
    .mat-column-weight {
      text-align: center !important;
    }
    .mat-column-symbol {
      text-align: center !important;
    }
    .mat-column-estado {
      text-align: center !important;
    }

    //agrega el color de fondo al row impar
    & tr.mat-row:nth-of-type(odd) {
      background: #dee3eb;
    }
    .mat-icon {
      color: #a34498;
    }

    & tr.mat-row:hover {
      transition: background-color 0.3s;
      color: #fafafa !important;
      background: $violetaKrikos;
      font-size: 14px;
      .mat-icon {
        color: #fafafa !important;
      }

      & .edit {
        display: block;
        & a {
          visibility: visible;
          color: white;
        }
      }
      & .status-pill {
        transition: all 0.3s;
        display: none;
        height: 25px;
        line-height: 25px;
      }
    }

    //cambia el color del texto al row seleccionado
    & .mat-row:hover > .mat-cell {
      color: white;
    }
  }

  //permite centrar los textos y agregar elipsis cuando son muy largos
  th,
  td {
    overflow: hidden;
    max-width: 225px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: "montserrat-regular";
  }

  //permite centrar los titulos del header de la grilla
  .mat-sort-header-container {
    display: flex;
    justify-content: start;
    text-align: center;
  }

  //estilos para el tooltip de la grilla
  //por default le agrega elipsis, esto lo saca
  .tooltip-tabla {
    word-break: break-all !important;
    white-space: normal !important;
    text-overflow: unset !important;
    width: auto;
  }

  //tamaño del input buscar, angular material
  .equivalencias__search-box {
    width: 350px !important;
  }
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  &:not(.LA_BUSQUEDA_NO_ARROJO_RESULTADOS) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .LA_BUSQUEDA_NO_ARROJO_RESULTADOS{
    margin-left: 16px !important;
  }
}

@mixin mat-toolbar-top {
  padding: 0;
  padding-left: 1rem;
  background: white;
  height: 39px;
}

@mixin mat-toolbar-sub {
  transition: 0.2s box-shadow;
  background-color: $principal;
  font-size: 14px;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-top: -1rem;
  .mat-toolbar-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    flex: 1 1 20rem;
    div {
      display: flex !important;
    }
    .ng-star-inserted {
      justify-content: right;
    }
  }
  button {
    outline: none !important;
  }

  button.mat-raised-button {
    padding: 0;
    background: white;
    box-shadow: none;
    animation: none;

    mat-icon {
      color: #434040;
      font-size: 16px;
      margin-top: 0.3rem;
    }
  }

  button.mat-icon-button {
    &:hover {
      color: $violetaKrikos;
    }
  }

  .mat-logo {
    display: block;
    width: 180px;
    margin-bottom: 6px;
    margin-top: 2px;
  }
}

@media screen and (max-width: 600px) {
  .logo-wrapper {
    img {
      width: 8rem !important;
    }
  }
}

@mixin buttonOutline {
  button {
    outline: none !important;
  }
}

@mixin buttonRaised {
  button.mat-raised-button {
    background: $violetaKrikos;
    mat-icon {
      color: $principal;
    }
  }
}

@mixin buttonIcon {
  button.mat-icon-button {
    &:hover {
      color: $violetaKrikos;
    }
  }
}

@mixin mat-toolbar-main-nav {
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #fff;
  border-top: 1px solid #d3d3d3;
  font-family: "montserrat-regular";
  a {
    color: #434040;
    font-weight: 400;
    text-decoration: none;
    font-size: 0.8rem;
    padding: 0 10px;
    font-size: 12px;
    &:nth-child(2) {
      padding-left: 0;
    }
  }
  button.mat-raised-button {
    background: $violetaKrikos;
    color: $principal;
    font-size: 12px;
    font-family: "montserrat-semibold";
  }

  @media screen and (max-width: 725px) {
    a {
      display: none;
    }
  }
}

@mixin connected-support {
  background: transparent;
  border: 1.5px solid $violetaKrikos;
  color: $violetaKrikos;
  font-weight: bold;
  outline: none;

  &:hover {
    cursor: pointer;
  }
}

@mixin mat-toolbar-support {
  background: #f2f2f2;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .mat-footer {
    min-height: 200px;
  }
}
@mixin mat-footer {
  background: #434142;
  color: $grisCopyright;
  font-size: 12px;
  text-align: initial;
  padding: 0.8rem 1rem;
  padding-top: 0.5rem;
  // height: 26.5%;

  fa-icon {
    display: inline-block;
    padding: 5px;
    color: #fff;
    margin-left: 13px;
  }
  .social {
    text-align: end;
    margin-top: 20px;
    margin-right: -10px;
  }
  .social_afip {
    text-align: end;
  }

  .icon_red {
    color: #dc3545;
  }

  .icon_white {
    color: white;
    font-size: 14px;
    font-family: "montserrat-semibold";
  }
  .copyright {
    color: #c7c7c7;
    font-size: 10px;
    font-family: "montserrat-regular";

    & a {
      color: #c7c7c7;
      font-size: 10px;
      font-family: "montserrat-regular";
    }
  }
  .contacto {
    height: 20px;
    & a {
      color: #c7c7c7;
      font-size: 10px;
      font-family: "montserrat-regular";
    }
  }

  .icons_media {
    float: right;
    margin-top: -30px;
    direction: rtl;
    width: 0;
  }
}

@mixin downloads-list {
  mat-card {
    mat-card-title.title {
      line-height: 1.42857;
      color: #a34498;
      text-transform: uppercase;
      font-size: 19px;
      font-weight: 700;
      text-align: center;
      padding: 10px;
      background-color: #f5e9f3;
      border-bottom: 0;
    }
    .text-center {
      text-align: center;
      margin-top: 25px;
    }

    //permite centrar los titulos del header de la grilla
    .mat-sort-header-container {
      display: flex;
      justify-content: center;
      text-align: center;
    }
    // columnas
    .mat-column-nombre {
      text-align: center !important;
    }
    .mat-column-fechaInicio {
      text-align: center !important;
    }
    .mat-column-peso {
      text-align: center !important;
    }
    .mat-column-control {
      text-align: center !important;
    }
  }
}

@mixin notificacion-panel {
  p.title {
    display: inline-block;
    line-height: 20px;
    padding-left: 5px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  div.content {
    background-color: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    // max-height: 450px;
    overflow-y: auto;
    position: relative;
    padding-left: 5px;
    border-top: 1px solid;
  }
  li {
    list-style-type: none;
  }
  ol {
    padding-inline-start: inherit;
  }
  mat-card {
    background-color: #eaedf2;
    display: flex;
    margin-bottom: 5px;
    padding: 10px;
    position: relative;
    display: flex;
    flex-grow: 1;
    box-shadow: none;
    flex-direction: none !important;
    border: none !important;
    box-shadow: none !important;
    flex-direction: unset;
    div.content-card {
      margin-left: 18px !important;
    }
    div.footer-card {
      text-align: left;
      margin-left: 1rem;
    }
  }

  span.solicitudes {
    margin-left: 0px;
    margin-bottom: 8px;
  }

  .disabled-repetitive-action {
    cursor: not-allowed;
    opacity: 0.65;
    filter: alpha(opacity=65);
    box-shadow: none;
    color: rgb(151, 153, 241);
  }
}

@mixin tituloFeature {
  .mat-toolbar {
    &.titulo-feature {
      background-color: #fff;
      height: 3rem;
      position: relative;
      border-left: 5px solid #000;
      margin: 5px 0 0;
      align-items: center;

      h1 {
        display: inline;
        padding: 0;
        font-size: 1.5rem;
        margin: 3px 0;
        font-weight: 400;
        font-family: "roboto-medium";
      }
    }
  }
}

@mixin matSelect {
  .mat-select-panel {
    .mat-option {
      &:hover {
        transition: all 0.3s;
        background-color: $violetaKrikos;
        color: $principal;
      }
    }
  }
}

@mixin inputLabelTop {
  .input-label-top {
    height: fit-content;
    display: inline-block;

    input {
      display: block;
    }

    label {
      display: block;
      font-size: 11px;
      margin-bottom: 0px;
      font-weight: 700;
    }

    .mat-icon {
      font-size: 1rem;
    }

    .mat-form-field {
      width: 100%;
    }

    .mat-form-field-infix {
      padding: 0.7em 0 0.5em 0;
      border-top: 0px;
    }
  }
}
