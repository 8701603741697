//Colores de projecto
//El guion bajo es por una cuestion de regla Sass, cuando se utiliza se da aviso a que no se compile ese archivo.

//paletas de colores para el theming, el 400 seria el tono medio
$krikos-light-primary: (
    50: #F7E6F8,
    100: #EAC1EE,
    200: #DA99E0,
    300: #CB70D3,
    400: #C44ACE,
    500: #B629C3,
    600: #A823BF,
    700: #941EB7,
    800: #821AB0,
    900: #6014A3,
    
    contrast: (
        50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        ));
        
$krikos-light-secondary: (

    50: #F9F9F9,
    100: #F3F3F3,
    200: #EAEAEA,
    300: #DADADA,
    400: #BEBBBB,
    500: #9F9C9C,
    600: #6F6F6F,
    700: #625F5F,
    800: #434040,
    900: #221F1F,

    contrast: (
        50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
));
        
$krikos-light-warn: (
    50: #ffecef,
    100: #ffd0d4,
    200: #ef9f9e,
    300: #e57b79,
    400: #f05e57,
    500: #f4513e,
    600: #e6483d,
    700: #d43f37,
    800: #c73930,
    900: #b73025,
    
    contrast: (
        50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        ));


$principal: #ffffff;
$negroKrikos: map-get($map: $krikos-light-secondary, $key: 800);
$grisKrikos: map-get($map: $krikos-light-secondary, $key: 600);
$violetaKrikos: map-get($map: $krikos-light-primary, $key: 400);
$violetaHover: map-get($map: $krikos-light-primary, $key: 200);
$grisToolbar: #5C6667;
$grisCopyright: #c7c7c7;
$verdeKrikos: #00C938;
$verdeBoton: #9fd245;
$rojoKrikos: #e6493d;
$rojoIconos: #dc3545;

//FondoToastr
$comercial : #c9e6f4;
$danger: #ffdcdc;
$success: #d1f2de;
$operativo: #ffe1d7;

//TextoToastr
$comercial : #1A3A61 ;
$danger: #CE0F0F ;
$success: #087735 ;
$operativo: #E25314 ;

